
import React from 'react'
 import * as OptimaEhandelShoppCSS from './css/optimaEhandelShop.module.css'

const OptimaEhandelShop = () => (
<div className={` ${OptimaEhandelShoppCSS.parent}`}>
{/* <h5 style={{ textAlign:'center' }}>just nu är vi inne i ett pågående arbete med att updatera både kassa och bokningssystem.<br></br>
    Det kan därför hända att alla våra tider/paket inte syns i bokningskalendern/e-handeln ännu.<br></br>
    Kontaka gärna oss så hjälper vi dig över telefon på 08-755 33 63.<br></br>
    tack för er förståelse</h5> */}
 
    <iframe src='https://danderyds_trafikskola_aboaeaeoa.web.stroptima.se/shop' title="e-handel"></iframe>
</div>
)
export default OptimaEhandelShop
