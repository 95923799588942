import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
// import Ehandel from '../components/ehandel' GAMMAL ANVÄNDS EJ MERA
import OptimaEhandeShop from '../components/optimaEhandelShop'
import PageHeader from '../components/pageHeader'

const ehandel = () => (
  <Layout>
    <PageHeader text='E-handel' />
    <Seo title='E-handel' />
    {/* <Ehandel />  GAMMAL ANVÄNDS EJ MERA */}
    <OptimaEhandeShop/>
  </Layout>
)

export default ehandel
